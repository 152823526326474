import loadGoogleMapsApi from 'load-google-maps-api';

(()=>{
  const target = document.getElementById('access');
  if(!target) return;

  const key = 'AIzaSyBaMWhtmM6_Sv8ErORf_aV0VIFLTU-Y1ec';

  let script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap`;
  script.defer = true;

  window.initMap = initMap;
  document.head.appendChild(script);
})();

const initMap = function(){
  const gmap = document.querySelector('.gmap');
  if(gmap) setMap(googleMaps, gmap);

  const gdmap = document.querySelector('.gdirection');
  if(gdmap) setDirectionMap(googleMaps, gdmap);

  const gsmap = document.querySelector('.gstreet');
  if(gsmap) setStreetMap(googleMaps, gsmap);
};

//map
const setMap = function(googleMaps, target){
  var latlng = target.innerText.replace(/([0-9\.,])/,'$1').split(',');
  var lat = latlng[0];
  var lng = latlng[1];
  var zoom = Number(latlng[2]) || 14;
  var centerPosition = new googleMaps.LatLng(lat,lng);
  var options = {
    zoom: zoom,
    center: centerPosition,
    mapTypeId: googleMaps.MapTypeId.ROADMAP
  };
  var map = new googleMaps.Map(target, options);
  map.setOptions({
    //styles: style,
    draggable			:true,
    scrollwheel			:false,
    disableDefaultUI	:false,
    scaleControl		:false,
    navigationControl	:true,
    mapTypeControl		:false,
    streetViewControl	:false,
    panControl			:true,
    panControlOptions:{
      position:googleMaps.ControlPosition.RIGHT_TOP
    },
    zoomControlOptions:{
      position:googleMaps.ControlPosition.RIGHT_TOP,
      style:googleMaps.ZoomControlStyle.LARGE
    }
  });
  var marker = new googleMaps.Marker({
    position: centerPosition,
    map: map
  });
};
const setStreetMap = function(googleMaps,target){
  var latlng = target.innerText.replace(/([0-9\.,\-])/,'$1').split(',');
  var lat = latlng[0];
  var lng = latlng[1];
  var zoom = Number(latlng[2]) || 14;;
  var heading = Number(latlng[3]) || 0;
  var pitch = Number(latlng[4]) || 10;
  var centerPosition = new googleMaps.LatLng(lat,lng);
  var panoramaOptions = {
    position: centerPosition,
    pov: {
      heading: heading,
      pitch: pitch
    }
  }
  var panorama = new googleMaps.StreetViewPanorama(target,panoramaOptions);
  panorama.setVisible(true);
};

const setDirectionMap = function(googleMaps,target){
  var latlng = target.innerText.replace(/([0-9\.,])/,'$1').split(',');
  var from = [latlng[0],latlng[1]];
  var to = [latlng[2],latlng[3]];
  var directionsService = new googleMaps.DirectionsService();
  var directionsDisplay = new googleMaps.DirectionsRenderer();
  var mapID = 'gdirection';
  var map_container = document.getElementById(`${mapID}1`);
  map_container.innerHTML = `
    <div id="${mapID}Canvas" class="gcanvas"></div>
    <div id="${mapID}Route" class="groute"></div>
    `;
  var mapOptions = {
    zoom: 7,
    center: new googleMaps.LatLng(from[0],from[1])
  };
  var map = new googleMaps.Map(document.getElementById(mapID+'Canvas'),mapOptions);
  directionsDisplay.setMap(map);
  directionsDisplay.setPanel(document.getElementById(mapID+'Route'));

  var request = {
    origin: new googleMaps.LatLng(from[0],from[1]),
    destination: new googleMaps.LatLng(to[0],to[1]),
    travelMode: googleMaps.TravelMode.DRIVING
  };
  directionsService.route(request, function(response, status) {
    if (status == googleMaps.DirectionsStatus.OK) {
      directionsDisplay.setDirections(response);
    }
  });
}
