
if (!window.console){ window.console = { log:function(msg){ }}; }
// @codekit-prepend jquery.colorbox-min.js;
// @codekit-prepend jquery.easing.1.3.js;

var $ = window.$;

//新規ウィンドウ
$.fn.blankWindow=function(){$(this).addClass('externalLink').click(function(){window.open(this.href, '_blank');return false;});}
//toTop
$.fn.toTop=function(){
	var $btn = $(this),isHidden = true;
	$(window).scroll(function () {
		if( $(this).scrollTop() > 50 ) {
			if( isHidden ) {
				$btn.stop(true,true).fadeIn();
				isHidden = false;
			}
		} else {
			if( !isHidden ) {
				$btn.stop(true,true).fadeOut();
				isHidden = true;
			}
		}
	});
}
//Loaded DOM

$(function(){
	$.support.transform  = typeof $("body").css("transform") === "string";
	$.support.transition = typeof $("body").css("transitionProperty") === "string";

	var page = {
		id 		: document.getElementsByTagName('body')[0].getAttribute('id'),
		classes	: false//document.getElementsByTagName('body')[0].getAttribute('class').split(' ')
	};

	//新規ウィンドウ
	var noBlank	= [
		'[href^="https://www.h-hattatsu.com/"]',
		'[href^="http://www.h-hattatsu.com/"]',
		'[href^="http://h-hattatsu.planpotdesignworks.com/"]',
		'[href^="http://h-hattatsu.lc/"]'
	];
	$('a[href^="http"]:not('+noBlank+')').blankWindow();
	$('a[href$=".pdf"]').blankWindow();

	//toTop
	$('#toTop>a').toTop();

	//jpg拡大
	$('a[href$=".jpg"],a[href$=".jpeg"],a[href$=".png"],a[href$=".gif"]').each(function(index, element) {
		$(this).colorbox({
			maxWidth:'100%', maxHeight:'95%',
			transition:'fade',
			title:$(this).children('img').attr('alt'),
			rel:'gallery'
		});
	});


	//文字拡大
	function setFontResizer(){
		var setID = '#font-size-nav';
		if(!$(setID).length) return false;
		if(!$.cookie('fontsize')) $.cookie('fontsize','m', { path: '/' });
		var fontSizeObj = {s:'12px',m:'14px',l:'16px'}
		var size = $.cookie('fontsize');
		$('#font-size-nav>dd>a').on('click',function(){
			$('#font-size-nav>dd>a.size-'+size).removeClass('current');
			$('body').removeClass('font'+fontSizeObj[size]);
			size = $(this).attr('data-size');
			$.cookie('fontsize',size, { path: '/' });
			$('body').addClass('fontsize-'+fontSizeObj[size]).css('font-size',fontSizeObj[size])
			$('.column2,.column3').each(function(index, val) {
				$(this).trigger('flatheight');
			});
			$(this).addClass('current');
			return false;
		});
		$('#font-size-nav>dd>a.size-'+size).trigger('click');
	}setFontResizer();

	//要素の高さ合わせ
	$('.column2,.column3').on('flatheight',function(){
		var target = this;
		var maxHeight = 0;
		$(this).find('li').each(function(index, el) {
			$(this).height('auto');
			if(maxHeight < $(this).innerHeight()) maxHeight = $(this).innerHeight();
		});
		$(this).find('li').innerHeight(maxHeight);
	}).each(function(index, val) {
		$(this).trigger('flatheight');
	});


	//ウィンドウイベントの初回登録
	function addWindowEvent(target,name,event){
		if(target['isAddEvent'+name]) return;
		var fn = function(){ $(target).trigger(name); };
		target['isAddEvent'+name] = true;
		if(!event) return;
		else if(event == 'resize') $(window).resize(fn);
		else if(event == 'scroll') $(window).scroll(fn);
	}

	//クリックメニュー
	$('#globalNav>ul>li').each(function(index, element) {
		var $target = $(this).children('ul');
		if(!$target.length) return this;
		$(this).children('a').on('click',function(){
			if($target.hasClass('open')){
				$('#globalNav>ul>li>ul').removeClass('open');
				return false;
			}
			$('#globalNav>ul>li>ul').removeClass('open');
			$target.addClass('open');
			return false;
		});
	});
	$('body').on('click',function(event){
		$('#globalNav>ul>li>ul').removeClass('open')
	});

	//スクロール
	$('#container-header .page-top').on('scrollcheck',function(event){
		addWindowEvent(this,event.type,'scroll');
		if($(window).scrollTop()<=0){
			$(this).fadeTo('500', 0.3);
		}else{
			$(this).fadeTo('500', 1);
		}
	}).trigger('scrollcheck');

	//ページ毎
	switch(page.id){
		case 'home' :
			$("#slides").slidesjs({
				width: 1280,
				height: 482,
				play: {
					active: false,
					effect: "fade",
					interval: 6000,
					auto: true
				},
				navigation: {
					active: false,
					effect:"fade"
				},
				pagination: {
					active: false,
					effect:"fade"
				},
				effect: {
					fade: {
						speed: 1000,
						crossfade: true
					}
				},
				callback: {
				}
			});
			addWindowEvent('#topSlider','sliderFix','resize');
		break;
		default:
		break;
	}
	function CLocationSearch(sSource) {

		/* 初期化処理 */
		var sAry = new Array();	/* アイテム配列を追加 */
		{
			var nCur = 0, nRet = 0;
			var sSearch = sSource.substr(1);
			var sName, sValue;

			nRet = sSearch.indexOf('=', nCur)		// 最初の'='を検索
			while(nRet >= 0) {
				// '='以前が変数名
				sName = sSearch.substring(nCur, nRet);
				nCur = nRet + 1;

				// 値を取り出す
				nRet = sSearch.indexOf('&', nCur);	// 変数の区切りを検索
				if (nRet == -1) nRet = sSearch.length + 1;		// なければ最後まで
				sValue = sSearch.substring(nCur, nRet);

				// 配列に格納
				sAry[sName] = sValue;

				// 次を検索
				nCur = nRet + 1;
				nRet = sSearch.indexOf('=', nCur);
			}
		}

		/* 値 */
		this.valueOf = function () {
			return sSource;
		}

		/* 値を取り出すメソッド */
		this.item = function (sVarname) {
			try {
				return sAry[sVarname];	//あれば返す
			} catch(e) {
				return '';				//なければ空文字を返す
			}
		}

		/* 要素数 */
		this.length   = function () {
			return sAry.length;
		}

		/* toString */
		this.toString = function () {
			return this.length + '';
		}

	}
});
